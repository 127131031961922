import api from "@/modules/general/axiosInstance.js"

export default {

    //Consigue todos los ítems
    getItems() {
        return api.get("/traerItemsCarro")
    },

    //Consigue todos los métodos
    getMethods() {
        return api.get("/traerMetodosCarro")
    },

    //Consigue todas las categorías
    getCategories() {
        return api.get("/traerCategoriasCarro")
    },

    //Consigue todas las configuraciones para este proyecto
    getConfigs() {
        return api.get("/getConfigsCarro")
    },

    //Inserta los datos recogidos de otra parte
    insert(datos) {
        return api.post("/insertToBitacoraCarro", datos)
    },

    guardarDatosVehiculo(ot_id){
        return api.get("/guardarDatosCarro/"+ot_id)
    }
}