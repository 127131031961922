<template>
  <v-card>
    <v-toolbar dark color="primary" height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-n15">Buscador</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="red" small @click="$emit('cerrar-modal')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-col>
      <v-text-field outlined dense v-model="buscador" @keyup="buscar">
      </v-text-field>
    </v-col>
    <v-expansion-panels>
      <v-expansion-panel v-for="(titulo, i) in titulos" :key="i">
        <v-expansion-panel-header>
          <v-row>
            <v-col style="text-align: left"> {{ titulo.text }}</v-col>
            <v-col
              style="text-align: center"
              :class="
                titulo.cantidad_resultados == 0 ? 'red--text' : 'green--text'
              "
              >coincidencias: {{ titulo.cantidad_resultados }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(resultado, i2) in titulo.resultados"
                :key="i2"
              >
                <v-list-item-content @click="redirigirBuscador(resultado.id_ubicacion)">
                  <v-list-item-title
                    style="font-size: 14px"
                    class="d-flex text-wrap"
                  >
                    {{ resultado.text }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-text> </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["categorias"],
  data: () => ({
    buscador: "",
    categorias_locales: [],
    titulos: [
      { text: "Categorias", cantidad_resultados: 0, resultados: [] },
      { text: "Items", cantidad_resultados: 0, resultados: [] },
      { text: "Metodos", cantidad_resultados: 0, resultados: [] },
    ],
  }),
  async created() {
    // this.categorias_locales = JSON.parse(JSON.stringify(this.categorias));
  },

  methods: {
    buscar() {
      if (this.buscador.replace(/ /g, "") != "") {
        this.titulos[0].resultados = [];
        this.titulos[0].cantidad_resultados = 0;
        this.titulos[1].resultados = [];
        this.titulos[1].cantidad_resultados = 0;
        this.titulos[2].resultados = [];
        this.titulos[2].cantidad_resultados = 0;

        this.categorias.forEach((categoria) => {
          if (categoria.categoria_item.toLowerCase().includes(this.buscador)) {
            this.titulos[0].resultados.push({
              text: categoria.categoria_item,
              id_ubicacion: categoria.id_ubicacion,
            });
            this.titulos[0].cantidad_resultados++;
          }
          categoria.items.forEach((item) => {
            if (item.item.toLowerCase().includes(this.buscador)) {
              this.titulos[1].resultados.push({
                text: item.item,
                id_ubicacion: item.id_ubicacion,
              });
              this.titulos[1].cantidad_resultados++;
            }

            item.metodos.forEach((metodo) => {
              if (metodo.metodo.toLowerCase().includes(this.buscador)) {
                this.titulos[2].resultados.push({
                  text: metodo.metodo,
                  id_ubicacion: metodo.id_ubicacion,
                });
                this.titulos[2].cantidad_resultados++;
              }
            });
          });
        });
      } else {
        this.titulos[0].resultados = [];
        this.titulos[0].cantidad_resultados = 0;
        this.titulos[1].resultados = [];
        this.titulos[1].cantidad_resultados = 0;
        this.titulos[2].resultados = [];
        this.titulos[2].cantidad_resultados = 0;
      }
    },

    redirigirBuscador(ubicacion){
      console.log(ubicacion);
      this.$emit('redirigir-buscador',{ubicacion:ubicacion});
      this.$emit('cerrar-modal');
    }
  },
};
</script>
<style lang=""></style>
